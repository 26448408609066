.user-img-loader{
    width: 50px;
    height: 50px;
    background-color: #EBEBEB;
    border-radius: 50%;
    float: left;
    margin-right: 15px;
}

.post-ppl-img {
    width: 100%;
    height: 250px;
    background-color: #EBEBEB;
    border-radius: 10px;
}

.loader-img {
    height: 150px;
    width: 100%;
    border-radius: 5px;
    background-color: #EBEBEB;
}

.loader-list {
    .property-header-loader {
        background-color: #F0F2F6;
        padding-top: 16px
    }

    .shorter {
        p{
            text-align: center;
            
            span {
                width: 50px;
                margin: auto;
            }
        }
    }
}