.login {
  height: 100vh;
  .login-back {
    position: absolute;
    top: 0;
    left: 0;
    
    &:hover i {
      color: #3b3e48 !important;
    }
  }
  
  .login-left, .login-right{
    height: 100%;
  }
  .login-left{
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .register-left{
    align-items: flex-start !important;
    
  }

  .alert-danger {
    position: relative;
    padding: 1rem !important;
    padding-right: 2rem !important;
    justify-content: start;
  }

  .login-container,.change-password-container {
    max-width: 440px;

    .login-form {
      :is(.email-input, .password-input,.text-input) {
        height: 52px;
      }

      .login-icon-password {
        background: none;
        border: none;
        margin-top: -45px;
        margin-right: 1.1em;
        font-size: 1.5em;
        position: relative;
      }

      .login-button {
        height: 52px !important;
        font-size: 1.2em;
      }
      .register-button{
        height: 52px !important;
        font-size: 1.2em;
        @media(max-width: 600px){
          width: 100% !important;
        }
      }
    }
  }

  .login-carousel {
    position: relative;

    .carousel,
    .carousel-inner,
    .carousel-item {
      height: 100%;
    }

    img {
      object-fit: cover;
      object-position: top center;
    }

    .login-carousel-caption {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.8)
      );
    }
  }

  a.no-link {
    text-decoration: none !important;
    color: #fff;
  }

  .register-left .login-container {
    max-width: 1000px;
    
  }
  a{
    text-decoration: underline !important;
    color: var(--bs-primary);
  }
}
