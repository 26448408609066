@import "~leaflet/dist/leaflet.css";
@import "../components/PropertyMap/PropertyMap.scss";
@import "../components/PropertyForm/PropertyForm.scss";
@import "../components/PropertyDescription/PropertyDescription.scss";
@import "../components/PropertyDownload/DownloadComponent.scss";
@import "../components/NeighbourhoodDescription/NeighbourhoodDescription.scss";
@import "../components/Tabs/Tabs.scss";
@import "../components/UnitsSelect/UnitsSelect.scss";
@import "../components/UnitsTable/UnitsTable.scss";
@import "../components/PropertyFormButton/PropertyFormButton.scss";
@import "../components/PropertyFinancial/PropertyFinancial.scss";
@import "./gallery.scss";
@import "./modal.scss";
@import "../components/Skeleton/Skeleton.scss";

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.detail {
  .detail-container {
    @media screen and (max-width: 992px) {
      overflow: hidden;
      .container{
        padding: 0 4px;
      }
    }
  }
  .detail-header {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 54px;
    height: 64px;
    z-index: 1002;
    box-sizing: border-box;

    &>div{
      @media screen and (max-width: 700px) {
        padding-right: 10px !important;
      }
    }

    h1 {
      font-size: 1.15rem !important;
      padding-left: 10px;
    }

    .logo-promo {
      width: 100px;
    }

    .reference {
      font-size: 12px;
      line-height: 1rem;
      text-transform: uppercase;
    }

    .back-arrow .back {
      @media screen and (max-width: 992px) {
        font-size: 1.25rem !important;
      }
    }

    .link-respuestas {
      &.invalid-level {
        pointer-events: none;
        
        span {
          color: #C1C5D2 !important;
        }

        svg {
          color: rgba(255, 115, 40, 0.5) !important;
        }
      }

      span {
        color: #212529;
      }
    }

    .col-share{
      @media screen and (min-width: 992px) {
        display: none !important;
      }
    }

    .bi-instagram{
      
      bottom: 10px;
      right: 0px;
    }
    .share-ig{
      width: 20px !important;
    }
    
    .files-dropdown {
      .dropdown-item {
        flex-flow: column;
      }
    }

    
  }
  .property-type {
    font-size: 12px;
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .property-bedroom,
  .property-meters {
    span {
      font-size: 13px;
    }
    svg {
      width: 19px;
    }
    i {
      font-size: 17px;
    }
  }

  .detail-price {
    width: 100%;
    .property-price {
      @media screen and (min-width: 992px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .property-units-price {
        font-size: 1rem;
      }

      .price-per-meter {
        font-size: 1rem;
        position: relative;
        bottom: 6px;
        @media screen and (min-width: 992px) {
          flex-direction: column;
          align-items: flex-start;
          position: static;
        }
      }
      .price {
        font-size: 1.75rem;
        margin: 0.2rem 0 0.15rem;
      }
    }
    .property-agent-commision {
      font-size: 0.95rem;
      text-transform: none !important;
      background: none !important;
      border: none !important;
      box-shadow: none !important;
      font-weight: normal;
      padding: 0;
      color: #00b3ac !important;
      margin-top: 0.75rem;
    }
  }

  .property-button-offer {
    height: 42px;
    min-width: 190px;
    @media screen and (max-width: 992px) {
      min-width: 100%;
      height: auto;
    }
  }

  .detail-gallery {
    height: 375px;
    min-width: 500px;
    @media screen and (max-width: 992px) {
      height: 300px;
      min-width: auto;
      max-width: 100%;
    }
    
    .gallery-counter {
      padding: 0.15rem 0.4rem;
      left: auto;
      right: 12px;
      i {
        font-size: 0.9rem;
      }
      span {
        font-size: 0.8rem;
      }
    }
    .toggle-map-gallery {
      position: absolute;
      right: 12px;
      top: 10px;
      z-index: 1001;

      .toggle-button {
        cursor: pointer;
      }

      // custom resize breakpoint
      @media screen and (max-width: 573px) {
        right: unset;
        top: unset;
        bottom: -15px;
        left: 50%;
        margin-left: -67.5px;
      }
    }
    .country-flag {
      position: absolute;
      top: 10px;
      left: 12px;
      z-index: 1;
    }
    .download-images {
      z-index: 1000;
      left: 12px;
      bottom: 10px;
      background-color: #fff;
      color: #fd7e14;
    }
  }

  .resume-section {
    @media screen and (max-width: 992px) {
      .resume-left {
        border-right: 0 !important;
      }
    }
    .developer-image {
      width: 40%;
      max-height: 80px;
      object-fit: contain;
      margin-right: 1rem;
      @media screen and (max-width: 600px) {
        width: 80%
      }
    }
  }

  .location-section{
    @media screen and (max-width: 992px) {
      .resume-left{
        border-right: none !important;
      }
    }
  }

  .form {
    // position: -webkit-sticky;
    // position: sticky;
    top: 54px + 64px;
  }
  .property-similars {
    .similar-arrow {
      position: absolute;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0.74;
      transition: all ease 200ms;
      &::before {
        content: none;
      }
      &.left {
        left: -40px;
      }
      &.right {
        right: -40px;
      }

      &:hover,
      &:active {
        opacity: 1;
      }

      &.slick-disabled {
        display: none !important;
      }

      @media screen and (max-width: 992px) {
        width: 32px;
        height: 32px;
        opacity: 1;
        svg {
          width: 20px;
          height: 20px;
        }
        &.left {
          left: -16px;
        }
        &.right {
          right: -16px;
        }
      }
    }
  }

  .detail-footer {
    bottom: -1px;
    border-bottom: 1px solid white;
    left: 0;
    z-index: 1000;
    position: -webkit-sticky;
    position: sticky;
  }

  .detail-content {
    .logo-promo {
      width: 100px;
      display: block;
      margin: 10px 0px;
    }

    @media screen and (max-width: 992px) {
      .units-table {
        padding-left: 10px;
        padding-right: 10px;
        &.units,
        &.garages {
          .units-table-row {
            position: relative;
            padding-right: 44px !important;
            padding-left: 4px;
          }
          .units-table-row-col {
            flex-basis: 33% !important;
            font-size: 0.9rem;
            padding: 0.5rem 0.3rem !important;
            &.units_table_col_1,
            &.units_table_col_2,
            &.units_table_col_0 {
              color: #3b3e48 !important;
            }

            &.units_table_col_3,
            &.units_table_col_4,
            &.units_table_col_5,
            &.units_table_col_6 {
              font-size: 0.85rem;
            }

            &.col-action {
              position: absolute;
              right: 2px;
              width: auto;
              top: 50%;
              padding: 0 8px;
              transform: translateY(-50%);
            }
          }

          .units-col-small{
            flex-basis: 12% !important;
          }
        }
        &.garages {
          .units-table-row-col {
            &.units_table_col_3,
            &.units_table_col_4,
            &.units_table_col_5 {
              font-size: 0.8rem;
            }
          }
          
        }
      }
    }
  }

  .financial-section {
    & > div {
      @media screen and (max-width: 992px) {
        border-right: none !important;
      }
    }
  }

  .toggle-share{
    background: white !important;
    border : none;  
    &:active{
      background: white;
      border : none;  
    }
  }
  
  .col-share-link{
    button:focus{
      outline: 0 !important;
      box-shadow: none !important;
    }
  }
  .menu-share{
    padding : 0 10px;
    
    a{
      padding: 10px;
      color : black;
      text-decoration: none;

      i{
        color : gray !important;
        &.twitter{
          color : #1DA1F2 !important
        }
        &.facebook{
          color : #1877F2 !important
        }
        &.whatsapp{
          color : #25D366 !important
        }
        &.linkedin{
          color : #0A66C2 !important
        }
      }

      &.share-copy{
        border-bottom: 1px solid #e6e6e6;
        display: inline-block;
        text-align: center;
        width: 100%;
        &:hover{
          text-decoration: underline;
        }
        i{
          margin-left: 10px;
        }
        span{
          background: #808080ba;
          padding: 5px;
          border-radius: 5px;
          color: white;
          right: 6px;
          top: -17px;
        }
      }
    }

  }

  .col-share-link .show > .btn-primary.dropdown-toggle{
    background-color: white;
  }

  
}

.lottie-container{
  z-index: 10;
}

.inmo-detail{
  min-height: 100vh;
  background-color: #f8f9fd !important;
  .detail-header{
    top : 0px;
  }
  .price-per-meter{
    display: none;
  }
  .toggle-map-gallery{
    display: none !important;
  }
}

.carousel-100 {
  &.carousel,
  & .carousel-inner,
  & .carousel-item {
    height: 100%;
  }

  .carousel-control-prev {
    justify-content: start;
    opacity: 1;
  }
  .carousel-control-next {
    opacity: 1;
    justify-content: end;
  }

  .property-gallery-arrow {
    width: 35px;
    height: 35px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    i {
      position: relative;
      left: -1px;
    }
    &.right {
      right: -17px;
      @media screen and (max-width: 992px) {
        right: 6px;
      }
      i {
        position: relative;
        left: 1px;
      }
    }
    &.left {
      left: -17px;
      @media screen and (max-width: 992px) {
        left: 6px;
      }
      svg {
        position: relative;
        left: -2px;
      }
    }
  }
}

.unit-modal-content {
  overflow: auto;
  
  .btn-download-plane {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -12px;
    margin: -20px auto;
    width: 250px;
  }
  .toggle-tour-virtual {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1001;

    .toggle-button {
      cursor: pointer;
    }
  }

  .promo-span{
    padding: 3px 14px;
    border-radius: 5px;
    font-size: 15px;
    top: -45px;
    font-weight: bold;
    left: 0;
  }
}

@media screen and (max-width: 992px) {
  .property-modal-units,
  .property-modal-garages,
  .property-modal-floors {
    .modal-body {
      padding: 0;
      overflow: hidden;
      .container {
        max-width: 100% !important;
        padding: 0;

        .unit-modal-content,
        .garage-modal-content,
        .floor-modal-content {
          border-radius: 0 !important;
          overflow-y: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }

          .image {
            height: auto !important;
          }
        }
      }
    }
  }

  canvas{
    border : 1px solid black;
  }

  
  
}

.loader-share{
  
  width: 100vw;
  top: 0;
    left: 0;
    height: 100vh;
    background: white;
    opacity: 0.5;
    z-index: 10000;
  .loader-share-icon{
    transform: translate(-50%, -50%);
    top: 50vh;
    left: 50vw;
  }
}

$dark-back : rgb(0 0 0 / 65%);
.share-img{
  object-fit: cover;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100% !important;

}
.share-container{
  
  object-fit: cover;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  position : relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 80px 0px;
  overflow: hidden;
  left: -9999px;
  //background : url("https://cdn1.infocasas.com.uy/web/63b4916ba6f13_n85-(1).png");
  img.main-img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  img.inmo-logo{
    // position: absolute;
    // top : 30px;
    // right: 0;
    // left: 0;
    //margin :auto;
    width: 200px;
    height: 200px;
    background-color: white;
    border-radius: 50%;
    padding: 10px;
    margin-top: 2rem;
    object-fit: cover;
  }

  .share-top{
    text-align: right;
    z-index: 2;
    
    .share-top-title{
      margin-top: 5rem;
      p{
        background: $dark-back;
        display: inline-block;
        padding: 10px;
        border-radius: 15px 0px 0px 15px ;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
    &.has-image{
      .share-top-title{
        margin-top: 2rem;
      }
    }
  }
  .share-top-left{
    margin-left: 20px;
    padding: 1rem;
    background: rgb(0 0 0 / 45%);
    border-radius: 0 0px 0px 70px;
  }

  .share-bottom-container{
    width: 100%;
    z-index: 2;
    .share-meetme{
      //text-align: right;
      margin-bottom: 2rem;
      p{
        background: $dark-back;
        display: inline;
        padding: 10px;
        border-radius: 0px 15px 15px 0px;
      }
    }
  }
  .share-bottom{
    width: 100%;
    display: flex;
    text-align: center;
    background-color: $dark-back;
    padding : 1rem 0;
    color : white;
    font-size: 25px;
    &>div{
      flex : 1;
      flex-direction: column;
      justify-content: center;
      display: flex;
      margin: 10px 0px;
      display: flex;
      align-items: center;
      width: 100%;
      
      &:not(:last-child){
        border-right: 1px solid #ffffff78;
      }
    }

    .share-location{
      .location-icon i{
        font-size: 35px;
      }
    }
  }

  h3{
    background-color: white;
    color : #fd7e14;
    //display: inline;
    padding: 10px 10px 5px 10px;
    border-radius: 10px;
    //position: absolute;
    bottom: 250px;
    // left: 50%;
    // transform: translate(-50%, 0);
    font-size: 45px;
    margin-bottom: 20px;
    margin-left : auto;
    margin-right: auto;
    margin-top: 2rem;
    text-align: center;
    font-weight: bold;
    //white-space: nowrap;
  }

  .share-price{
    
    padding: 10px;
    padding : 10px;

    .property-price{
      text-align: center;
      div.price{
        font-weight: 600;
        white-space: nowrap;
        & >div{
          font-size: 25px;
          width: 100%;
          text-align: center;
        } 
        small{
          font-size: 19px !important;
          margin-bottom: 5px;
          width: 100%;
          text-align: center;
          display: block;
        }
      }
    }
    .property-price .price small{
      font-size: 25px;
    }
    .property-price .price-per-meter{
      font-size: 34px;

    }

  }

  p{
    //background-color: white;
    color : white;
    padding: 0;
    margin-bottom: 10px;
    border-radius: 10px;
    font-weight: 600;
    //position: absolute;
    //display: flex;
    // left: 50%;
    // transform: translate(-50%, 0);
    //margin : auto;
    font-size: 23px;
    text-align: center;
    width: 100%;
    display: block;

    &.find-us{
      //font-size: 25px;
    }
    &.property-address{
      bottom: 270px;
      margin-top : 5px;
    }
    &.property-hood {
      bottom: 180px;
    }

    &.property-handoverdate{
      bottom : 80px;
      //font-size: 28px;
    }

    &.property-law{
      //font-size: 25px;
    }

  }
}

#parentShareImg{
  // position: absolute;
  // left: -9999px;
}


.new-estimator {
  font-size: 12px;

  // .tooltip-arrow {
  //   margin-left: 45px;

  //   @media screen and (max-width: 990px) {
  //     margin-left: 20px;
  //   }
  // }

  .new-estimator-close {
    width: 20px;
    height: 20px;
    padding: 0px;
    font-size: 12px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    float: right;
  }
}