.card-img-overlay {
  .gradient-overlay {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7243230055694152) 0%,
      rgba(255, 255, 255, 0) 45%
    );
    opacity: 0;
    transition: 0.3s all;
  }

  button {
    margin-left: -14%;

    &:hover {
      background-color: #dee2e6 !important;
    }
  }

  &:hover {
    .gradient-overlay {
      opacity: 0.3;
    }
  }
}
