@import "./../CountryFlag/CountryFlag.scss";
@import "./../PropertyAgentCommision/PropertyAgentCommision.scss";
@import "./../PropertyBedroom/PropertyBedroom.scss";
@import "./../PropertyMeters/PropertyMeters.scss";
@import "./../PropertyPrice/PropertyPrice.scss";

.property-card {
  min-height: 348px;
  transition: 200ms all ease;

  &:hover {
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.15) !important;
  }
  .property-card-cover {
    height: 150px;
    overflow: hidden;
    position: relative;
    .property-gallery {
      border-start-end-radius: 4px;
      border-start-start-radius: 4px;
    }
    .static-image {
      object-fit: cover;
      border-start-end-radius: 4px;
      border-start-start-radius: 4px;
    }
    .country-flag {
      position: absolute;
      top: 10px;
      left: 12px;
      z-index: 1;
    }
    .property-agent-commision {
      position: absolute;
      top: 2px;
      right: 12px;
      z-index: 1;
    }
    .overlay-promo {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      .logo-promo {
        width: 35%;
      }
    }
  }
  .property-card-body {
    padding: 10px 12px;
    min-height: 185px;
    flex: 1;
    .property-tags {
      display: flex;
      gap: 5px;
      flex-flow: row wrap;

      .tag-hand-over {
        background-color: #C1C5D2;
        color: #3B3E48;
        border-radius: 5px;
        padding: 2.5px 5px;
        font-size: 10px;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
      }
    }
    .property-card-location {
      font-size: 12px;
    }
    .property-card-title {
      font-size: 16px;
    }
    .h-35 {
      height: 35px;
    }
  }
}
