.modal-dialog.modal-lg.modal-dialog-centered {
    justify-content: center;
}

.modal-dialog.modal-sm.modal-dialog-centered {
    justify-content: center;
}

.modal-dialog {
    @media screen and (max-width: 992px) {
        max-width: 700px !important
    }

    .modal-container {
        background-color: rgba(0, 0, 0, 0) !important;
        font-family: 'Rubik', sans-serif;
    
        .popup-container {
            width: 800px;
            height: 400px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            position: relative;

            &:hover {
                cursor: pointer;
            }
    
            @media screen and (max-width: 992px) {
                width: 370px;
                height: 500px;
                align-items: start;
                margin: auto;
            }

            .contador-container {
                display: flex;
                justify-content: end;
                margin-right: 86px;
                margin-top: 30px;

                @media screen and (max-width: 992px) {
                    margin-top: 32px;
                    margin-right: 14px;
                }

                .clock {
                    width: 130px;
                    margin-right: 25px;

                    @media screen and (max-width: 992px) {
                        width: 100px;
                    }
                }

                .text {
                    color: #fff;
                    background: #282828;
                    border-radius: 10px 0px 0px 10px;
                    padding: 10px;
                    font-size: 16px;
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 992px) {
                        font-size: 12px;
                        padding: 5px 8px;
                    }
                }

                .contador {
                    display: flex;
                    align-items: center;
                    margin-left: 0px;
                    gap: 10px;
                    border: 2px solid #fff;
                    border-radius: 0px 10px 10px 0px;
                    padding: 5px 12px;

                    @media screen and (max-width: 992px) {
                        justify-content: center;
                        padding: 5px;
                    }

                    .time-block {
                        display: flex;
                        flex-flow: column;
                        color: #FFF;
                        margin: 0px !important;
                        line-height: 45px;

                        @media screen and (max-width: 992px) {
                            line-height: 30px;
                        }

                        &_number {
                            font-size: 35px;
                            font-weight: 600;
                            text-align: center;

                            @media screen and (max-width: 992px) {
                                font-size: 20px;
                                line-height: 20px;
                            }
                        }

                        &_text {
                            font-size: 20px;
                            line-height: 20px;
                            font-weight: 200;
                            text-transform: uppercase;
                            text-align: center;

                            @media screen and (max-width: 992px) {
                                font-size: 12px;
                                line-height: 12px;
                            }
                        }    
                    }

                    .separator {
                        width: 2px;
                        height: 90%;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}