.in-list {
    margin: 0px !important;
    margin-right: 10px;
    float: right;
    background: #ffffff;
    padding: 5px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    &.toggle-list {
      margin-left: 10px !important;
    }
}

.btn-toogle-list {
    background-color: transparent !important;
    border: none;

    &:hover {
        background-color: transparent !important;
    }
    
    i, span, svg {
      color: #3B3E48;
    }

    &.active {
      background-color: #F0F2F6 !important;
      
      i, span, svg {
        color: #FF7328;
      }
    }
}

.container-toggle-list {
  padding: 5px;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px;
}

.new-list {
    font-size: 12px;
  
    .tooltip-arrow {
      margin-left: 45px;
  
      @media screen and (max-width: 990px) {
        margin-left: 20px;
      }
    }
  
    .new-list-close {
      width: 20px;
      height: 20px;
      padding: 0px;
      font-size: 12px;
      border-radius: 50%;
      background-color: transparent;
      border: none;
      float: right;
    }
}