@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
// overwrite theme variables
// colors
$primary: #ff7328;
$primary-light: rgba(255, 115, 40, 0.2);
$secondary: #3b3e48;
$dark: #202227;
$light: #c1c5d2;

// break-points
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
) !default;

// border-radius
$border-radius: 0.4rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 0.6rem !default;
$border-radius-pill: 50rem !default;

// font-family
$font-family-sans-serif: "Open Sans", system-ui, -apple-system, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// change primary button text color
.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-outline-primary:hover {
  color: white !important;
}

.bg-xs-light {
  background-color: #f8f9fd !important;
}

.bg-sm-light {
  background-color: #f0f2f6 !important;
}
.bg-sm-light-hover {
  transition: all 200ms ease;
  &:hover {
    background-color: #f0f2f6 !important;
  }
}

.bg-green {
  background-color: #00b3ac !important;
}

.bg-blue {
  background-color: #0f3a8e !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-secondary-light {
  background-color: #E7E7E7 !important;
}

.bg-primary-light {
  background-color: $primary-light !important;
}

.fw-500 {
  font-weight: 600 !important;
}
.fs-sm {
  font-size: 0.875rem !important;
}
.fs-xs {
  font-size: 0.8rem !important;
}
.fs-xxs {
  font-size: 0.65rem !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-secondary-light {
  border: 1px solid #ced4da !important;
}

.c-default {
  cursor: default;
}

.text-button {
  font-size: 1rem;
}

.text-secondary {
  color: $secondary !important;
}

.text-light {
  color: $light !important;
}

.text-dark {
  color: $dark !important;
}

.text-light-secondary {
  color: #8e929e !important;
}

.text-green {
  color: #00b3ac !important;
}


// import bootstrap style
@import "~bootstrap/scss/bootstrap.scss";


// Para evitar conflictos con tailwind y daysiUI

svg{
  display: inline !important;
}

.btn-primary{
  background-color: var(--bs-primary) !important;
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:hover{
  background-color: #ff8848 !important;
  border-color: #ff813e !important;
}

.btn-primary.bg-white{
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.btn-close{
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
}

h3{
  font-size: 1.75rem !important;
}

.main-container{
  min-height: 500px;
  padding: 2rem;
}

.btn-green{
  background-color: #00b3ac !important;
  color: white !important;
  &:focus{
      box-shadow: 0 0 0 0.25rem #00b3ac36;
  }
  // background-color: red;
  &:hover{
      background-color: #00b3acd1 !important;

  }

}

ul{
  padding: 0 !important;
}

a{
  text-decoration: none !important;
  color: black !important;
}

.container{
  max-width: 1200px !important;
}

.error-bg{
  background-color: #fccbcb;
}