.post-profile {
  .post-profile-image {
    width: 50px;
  }
  .publish-date {
    font-size: 13px;
  }
  .label-detail {
    font-size: 14px;
  }
}

.post-info-header {
  font-size: 14px;
}
