.fixed-alert{
    position: fixed !important;
    
    right: 0 !important;
    width: calc(100% - 6rem) !important;
    padding: 1rem !important;
    padding-right: 3rem !important;
    z-index: 2000;
    max-width: 600px;
    transition: transform 5s;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    justify-content: flex-start !important;
  
  }

  .fixed-alert-top{
    top: 2rem !important;
    bottom: auto !important;
  }
  
  .fixed-alert-bottom{
    top: auto !important;
    bottom: 2rem !important;
  }
  

  
  .fixed-alert.show{
  
    animation: shake 0.2s ;
    transform: translateX(-30px) translate3d(0, 0, 0) ;
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  @keyframes shake {
    0% {
      transform: translate3d(-10px, 0, 0);
    }
    10% {
      transform: translate3d(-20px, 0, 0);
    }

    90% {
        transform: translate3d(-28px, 0, 0);
    }
    
  }

//   .fixed-alert.show{
  
//     animation: shake 0.5s ;
//     transform: translateX(-60px) translate3d(0, 0, 0) ;
//     backface-visibility: hidden;
//     perspective: 1000px;
//   }
  
//   @keyframes shake {
//     0% {
//       transform: translate3d(-10px, 0, 0);
//     }
//     10%, 90% {
//       transform: translate3d(-61px, 0, 0);
//     }
    
//     20%, 80% {
//       transform: translate3d(-59px, 0, 0);
//     }
  
//     30%, 50%, 70% {
//       transform: translate3d(-62px, 0, 0);
//     }
  
//     40%, 60% {
//       transform: translate3d(-59px, 0, 0);
//     }
//   }
  