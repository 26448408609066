.property-price {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: space-between;
}

.property-price .price small {
  font-size: 12px;
}

.property-price .price span {
  font-size: 21px;
  margin-right: 4px;
}

.property-price .price-per-meter {
  font-size: 14px;
}
