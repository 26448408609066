.footer-interactions {
    color: #3B3E48;
    
    button {
        background-color: white;
        border: none;
        border-radius: 10px;

        &:hover {
            background-color: #f0f2f6;
        }
    }

    .reflect-icon {
        transform: rotateY(180deg);
        width: 16px;
        display: inherit;
    }

    img {
        width: 24px;
        height: 100%;
    }

    .like-1 {
        right: 15%;
    }

    .like-2 {
        right: 30%;
    }
}

.social-links i {
    transition: .3s ease all;
    &:hover {
        color: #ff7328;
    }
}