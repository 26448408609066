.courses-list{
    a:hover{
        color : black !important;
    }
}

.course-detail{
    .course-image{
        height: 100%;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .top-course-container{
        grid-template-columns: 1fr 2.5fr;
    }

    .financial-section{
        min-height: 300px;
        a{
            color: var(--bs-primary);
        }
    }

    
}
