.user-profile-form{

    #formFile{
        &:focus{
            border-color: var(--bs-gray-400) !important;
        }
    }
    
    .tw-input{
        height: 2.6rem !important;
    }

    
    .color-picker{
        position: absolute;
        top: 2.3rem;
        right: 0;
        z-index: 2;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important ;

    }

    .color-show{
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        top: 10px;
        border-radius: 5px;
    }

    button.dropdown-toggle{
        width: 100%;
        text-align: left;
        background-color: white !important;
        border : 1px solid #cecece !important;
        position: relative;
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        padding:0;
        border: none !important;

        &:focus{
            border-color : 1px solid #cecece !important;
            box-shadow: none !important;
          }
      
          &.invalid{
            border : 1px solid #dc3545 !important;
          }

          input{
            color: black;
          }
      
    }

    .dropdown-menu{
        padding: 0;
    }

    .dropdown-item{
        padding: 5px;
        &:hover{
            background-color: white !important;
        }
    }

    .image-container{
        min-height: 345px !important;
    }
}


.btn-save-profile{
    @media (min-width: 574px) {
        width: auto !important;
    }
}