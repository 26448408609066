$maxWidthTablet : 992px;
$maxWidthMiddle : 768px;
$maxWidthMobile: 576px;
.user-guarantees{
    min-height:800px;

    .number-guarantees{
        text-align: right;
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 0;
    }

    .total-full{
        color: black !important;
        border: 1px solid black !important;
        &.active, &:hover{
            background-color: black;
            color: white !important;
        }
    }
    .total-successfull{
        border: 1px solid #0fc382 !important;
        color : #0fc382 !important;
        &.active, &:hover{
            background-color: #0fc382;
            color : white !important;
        }
    }
    .total-rejected{
    
        border: 1px solid #f03741 !important;
        color : #f03741 !important;
        &.active, &:hover{
            background-color: #f03741;
            color : white !important;
        }
    }
    .total-pending{
        border: 1px solid #ffbe14 !important;
        color : #ffbe14 !important;
        &.active, &:hover{
            background-color: #ffbe14;
            color : white !important;
        }
    }
    .total-commission{
        cursor : auto !important;
        color : var(--bs-primary) !important;
        border-color: var(--bs-primary) !important;
    }

    .total-guarantees{
        flex: 1;
        cursor: pointer;
        &:not(:first-child){
            margin-left: 10px;
        }
        h5 {
            font-size: 19px !important;
        }

        h5.show-mobile{
            display: none;
        }
        h5.show-desktop{
            display: inline-block;
        }
        @media only screen and (max-width: $maxWidthTablet)  {
            width: 22%;
            flex: none;
            margin-bottom: 1rem;
            h5{
                font-size: 14px !important;
            }
            &>div {
                p{
                    font-size: 30px;
                    line-height: 30px;
                }
                img{
                    height: 30px;
                }
            }
        }

        @media only screen and (max-width: $maxWidthMobile){
            h5{
                font-size: 10px !important;
            }
            h5.show-mobile{
                display: inline-block;
                text-transform: uppercase;
            }
            h5.show-desktop{
                display: none;
            }
            &>div {
                p{
                    font-size: 24px;
                    line-height: 24px;
                }
                img{
                    height: 20px;
                }
            }
        }
        
        img{
            filter: brightness(110%);
            height: 58px;
            width: auto;
        }
    }

    

    .table-guarantees{
        li{
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-radius: 10px;
            border : 1px solid #cecece;
            margin-bottom: 1rem;
            font-size: 15px;
            border-right: 3px solid #cecece !important;
            
            &:first-child{
                border-radius: 10px;
                svg{
                    cursor: pointer;
                }
            }
            &>div{
                padding: 10px;
                text-align: center;

                &:first-child{
                    width: 24%;
                }
                &:nth-child(2){
                    width: 12%;
                }
                &:nth-child(3){
                    width: 10%;
                }
                &:nth-child(4){
                    width: 15%;
                }
                &:nth-child(5){
                    width: 14%;
                }
                &:nth-child(6){
                    width: 18%;
                }
                &:nth-child(7){
                    width: 7%;
                    a{
                        color: black !important;
                    }
                    
                }
                &:nth-child(8){
                    width: 2px;
                    background-color: red;
                    height: max-content;
                    padding: 2px;
                }

                h6{
                    margin-bottom: 0;
                    font-weight: bold;
                    
                }
            }

            &:not(:first-child){
                &>div{
                    &:nth-child(6){
                        font-size: 13px;
                        color: #929292;
                    }
                }
            }

            // Aprobado por aseguradora
            &.status-type-3A{
                border-right: 3px solid #0fc382 !important;
                & > div:nth-child(5),.status-guarantee{
                    color: #0fc382;
                    &::before{
                        background-color: #0fc382;
                        border : 2px solid #c6e9dc;
                    }
                }
            }

            //Rechazado por aseguradora
            &.status-type-3B{
                border-right: 3px solid #f03741 !important;
                & > div:nth-child(5),.status-guarantee{
                    color: #f03741;
                    &::before{
                        background-color: #f03741;
                        border : 2px solid #fab9bc;
                    }
                }
            }

            //Pendiente contratos
            &.status-type-4{
                border-right: 3px solid #ffbe14 !important;
                & > div:nth-child(5),.status-guarantee{
                    color: #ffbe14;
                    &::before{
                        background-color: #ffbe14;
                        border : 2px solid #fcebbf;
                    }
                }
            }

            //Finalizado con éxito
            &.status-type-7{
                background-color: #0fc382;
                color: white;
                font-weight: bold;
                border-color: #0fc382 !important;
                &>div:nth-child(6),.status-guarantee{
                    color : white !important;
                }
            }

            &.status-type-3A ,&.status-type-3B ,&.status-type-4{
                & > div:nth-child(5){
                    display: flex;
                    align-items: center;
                    &>div{
                        width: calc(100% - 10px);
                    }
                    &::before{
                        content: '';
                        position: relative;
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                    }
                }
            }
            
        }

        @media only screen and (max-width: $maxWidthTablet)  {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 10px;
            grid-auto-rows: minmax(100px, auto);

            li:first-child{
                display: none;
            }
            li{
                flex-direction: column;
                padding: 1rem;
                flex-wrap: wrap;
                &>div{
                    width: 100% !important;
                    padding: 2px;
                    text-align: left !important;
                    &:nth-child(1){
                        position: relative;
                        order : 2;
                        &::before{
                            content: 'Cliente: ';
                            position: relative;
                            font-weight: bold;
                        }
                    }
                    &:nth-child(2){
                        order: 1;
                        img {
                            height: 40px;
                            width: auto !important;
                        }
                    }
                    &:nth-child(3){
                        &::before{
                            content: 'Tipo: ';
                            position: relative;
                            font-weight: bold;
                        }
                        order : 3;
                    }
                    &:nth-child(4){
                        &::before{
                            content: 'Actualización: ';
                            position: relative;
                            font-weight: bold;
                        }
                        order : 4;
                    }
                    &:nth-child(5){
                        order : 5;
                    }
                    &:nth-child(6){
                        order : 6;
                        text-align: justify;
                        margin-top: 5px;
                    }
                    &:nth-child(7){
                        order: 7;
                        min-height: 25px;
                        margin-top: 10px;
                    }
                    
                }
                
            }
        }

        @media only screen and (max-width: $maxWidthMiddle)  {
            grid-template-columns: repeat(2, 1fr);
        }

        @media only screen and (max-width: $maxWidthMobile)  {
            grid-template-columns: repeat(1, 1fr);
            li{
                &>div{
                    &:nth-child(7){
                        min-height: auto !important;
                    }
                }
            }
        }
    }

    .guarantees-pagination{
        li{
            cursor: pointer;
            background-color: var(--bs-gray-200);
            color : black;
            &.active {
                background-color: var(--bs-primary);
                color: white;
            }
            &.pagination-move{
                &.disabled{
                    opacity: 0.3;
                    pointer-events: none;
                }
            }
        }

    }

    .nav-types{
        .btn-filter{
            display: none !important;
        }
        @media only screen and (max-width: $maxWidthMobile){
            .btn-filter{
                display: flex !important ;
                
            }
            position: fixed;
            background: white;
            z-index: 2;
            width: 100%;
            padding: 7px 1rem;
            top: 54px;
            left: 0;
            margin-bottom: 0px !important;
            border-bottom: 1px solid #cecece;

            &>div {
                margin-bottom: 0 !important;
                &>div{
                    margin-bottom: 0px !important;
                }
            }
        }
    }

    @media only screen and (max-width: $maxWidthMobile){
        margin-top: 60px;
    }


    
    
    .info-no-warranties{
        p.msg{
            border-bottom: 1px solid #cecece;
            padding-bottom: 15px;
        }
        p{
            text-align: left;
        }
        .info-card{
            border: 1px solid #ff7328;
            border-radius: 15px;
            margin: auto;
            text-align: center;
        }
        ul{
            text-align: left;
        }
        h4{
            color : var(--bs-primary);
            text-align: center;
        }
        button{
            margin : auto;
        }
    }

    .total-empty{
        border-radius: 10px;
        border : solid 1px #cecece;

    }
    .content-empty{
        border : 2px solid var(--bs-primary);
        border-radius: 10px;
        border-top-width: 6px;
    }
    .top-benefit{
        max-width: 800px;
        margin: auto;
    }

    .warranty-steps {
        padding-bottom: 80px;
        &>div{
            padding: 10px 2px;
            width: 23%;
            &.arrow-icon{
                width: 30px;
            }
        }
       .arrow-icon svg path{
            stroke: #cccccc;
            stroke-width: 2;
        }
        p{
            text-align: center;
            margin-bottom: 0;
        }
        
        small{
            font-size: 12px;
            text-align: center;
            width: 100%;
            display: inline-block;
            color: gray;
        }

        img{
            max-width: 100%;
        }
        .extra-info{
            top: 50px;
            width: calc(100% + 30px);
            left: -15px;
        }
        .extra-info-aval{
            top: 50px;
            left: 0;
        }
    }

    @media only screen and (max-width: $maxWidthTablet){
        .warranty-steps{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &>div{
                align-items: center;
                text-align: center;
                width: 100% !important;
                &.arrow-icon>svg{
                    transform: rotate(90deg);
                }
            }

            .extra-info{
                top: 50px;
                width: 100%;
                left: 0;
            }
            .aval{
                padding-bottom: 60px;
            }
        }

        .btn-open button{
            width: 100%;
            max-width: 400px;
            margin: auto;
        }
        
    }



}

.modal-warranty ,.user-guarantees{
    .top-search{

        .search-name{
            input{
                padding-left: 35px;
                padding-right:25px;
            }
            
            i.bi-search{
                top : 5px;
                left: 10px;
            }

            i.bi-x{
                top : 2px;
                right: 13px;
                font-size: 22px;
                cursor: pointer;
            }
        }
        input,select{
            background-color: var(--bs-gray-100);
            border: none;
            
        }
        input[type=date]{
            max-width: 150px;
        }
        select{
            appearance: auto;
            max-width: 200px;
            overflow:hidden; 
            white-space:nowrap; 
            text-overflow:ellipsis;
            option:first-child{
                color : gray;
            }
        }
        .date-filter{
            
            i.bi-x{
                top : 1px;
                right: 33px;
                font-size: 22px;
                cursor: pointer;
            }
        }
        
    }
    .top-search{

        @media only screen and (max-width: $maxWidthTablet)  {
            width: 100% !important; 
            order : 2;
            
            input {
                width: 100%;
                margin-bottom: 1rem;
                max-width: 100%;
            }
            select{
                width: 100%;
                max-width: 100%;
            }
            .select-container{
                width: 48%;
                margin-bottom: 20px;
            }
            .date-filter{
                width: 100%;
                &>div{
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    width: 50%;
                    &:first-child{
                        &::before{
                            content: 'Desde';
                            display: relative;
                            margin-right: 10px;

                        }
                    }
                    &:last-child{
                        &::before{
                            content: 'Hasta';
                            display: relative;
                            margin-right: 10px;

                        }
                    }
                    input{
                        margin-bottom: 0;
                    }
                    
                }
                span{
                    display: none;
                }
                i.bi-x{
                    right: 50px;
                }
            }
            input[type=date]{
                max-width: 100%;
            }
            & > div{
                width: 100%;
            }
            
            
        }

        @media only screen and (max-width: $maxWidthMobile)  {
            .date-filter{
                &>div{
                    width: 100%;
                }
            }
            .select-container{
                width: 100%;
            }
           
        }
    }

    .btn-new-warranty{
        text-align: right;
        @media only screen and (max-width: $maxWidthTablet)  {
            order : 1;
            margin-bottom : 1rem;
            width: 100%;
            text-align: right;
        }
       
    }

}

.user-activity{

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
        font-weight: bold;
    }

    .nav-tabs{

        @media only screen and (max-width: $maxWidthMobile)  {
            .nav-item{
                width: 30%;
                display: flex;
                .nav-link{
                    padding: 5px 10px;
                    font-size: 14px;
                    font-weight: bold;
                    width: 100%;
                    white-space: wrap;
                }
            }
            
        }
        .nav-link{
            color : var(--bs-black-200);
        }
        

    }

    .tw-card {
        border-radius: 0px 0px 1rem 1rem;
        border-top: none !important;
    }


}

.user-profile{

    .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
        // color: var(--bs-primary) !important;
        font-weight: bold;
    }

    .nav-tabs{

        @media only screen and (max-width: $maxWidthMobile)  {
            .nav-item{
                width: 30%;
                display: flex;
                .nav-link{
                    padding: 5px 10px;
                    font-size: 13px;
                    font-weight: bold;
                    width: 100%;
                    white-space: wrap;
                }
            }
            
        }
        .nav-link{
            color : var(--bs-black-200);
        }
        

    }

    .tw-card {
        border-radius: 0px 0px 1rem 1rem;
        border-top: none !important;
    }

    .user-form{
        .tw-divider{
            display: none;
        }
    }

    .login-icon-password {
        background: none;
        border: none;
        margin-top: -45px;
        margin-right: 1.1em;
        font-size: 1.5em;
        position: relative;
        top: 8px;
    }

    .user-level-title{
        background-color: var(--bs-primary);
        color : white ; 
        font-size: 14px;
        padding: 5px 15px;
        border-radius: 10px;
        font-weight: 700;
        top: 15px;
        right: 20px;
    }
}