.property-description {
  position: relative;
  &:not(.expand) {
    p {
      overflow: hidden;
      text-overflow: clip;
      display: -webkit-box;
      -webkit-line-clamp: 7; /* number of lines to show */
      line-clamp: 7;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }
  }

  .more {
    width: 100%;
    text-align: right;
    display: inline-block;
    padding-left: 0px;
    line-height: 1.2rem;
  }

  &.expand {
    p {
      margin-bottom: 22px;
    }
    .more {
      bottom: -22px;
    }
  }
}
