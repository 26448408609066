.rmdp-container {
    width: 100%;

    input.rmdp-input {
        padding: 17px;
        width: 100%;
    }

    svg.icon {
        margin-right: 10px;
    }
}