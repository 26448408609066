.course-card{
    min-height: 380px;
    transition: 200ms all ease;

    .img-container{
        min-height: 250px;
    }
    img{
        height: 100%;
        object-fit: cover;
        object-position: center;
    }

    &:hover{
        box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.15) !important;
    }

    .course-content{
        flex : 1;
    }
    p:hover, span:hover,small:hover{
        color: black !important;
    }
    
}

