$maxWidthTablet : 992px;
$maxWidthMiddle : 768px;
$maxWidthMobile: 576px;

.appraisals{

    min-height: 80vh;
    h2{
        font-size: 25px;
        margin-bottom: 2rem;
    }

    h3{
        font-size: 22px !important;
    }

    .content-empty{
        border : 2px solid var(--bs-primary);
        border-radius: 10px;
        border-top-width: 6px;
    }

    .top-steps{
        width: 200px;
        .step{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border : 1px solid  var(--bs-primary);
            color:  var(--bs-primary);
        
            &.passed{
                background-color: var(--bs-primary);
                color: white;
            }
            &.active{
                background-color: var(--bs-primary);
                color: white;
            }
        }
        
        .connector {
            flex-grow: 1; //fill the space
            width: 10px;
            content: "";
            display: block;
            height: 1px;
            background-color: var(--bs-primary);
        }
    }

    .appraisal-companies-container{

        .appraisal_company{
            border-radius: 15px;
            width: 50%;
            max-width: 300px;

            .appraisal-name{
                font-size: 18px;
            }
    
            &.active {
                background-color: lavenderblush;
            }
            img{
                border-radius: 15px 15px 0 0;
                width: 100%;
                height: 150px;
                object-fit: contain;
            }
            .regular-price{
                font-size: 13px;
                color: gray;
                span{
                    text-decoration: line-through;
                }

            }
            button{

            }
        }

        
    }
    .company-message{
        min-height: 20px;
        p{
            color: red;
        }
    }


    .alert-danger{
        position : relative !important;
        padding: 1rem 1rem;
        margin-top: 1rem;
    }

    .border-color-green{
        border: 1px solid #00b3ac;
    }

    .appraisal-payment{

        max-width: 500px;
        ul{
            list-style: disc !important;

            .text-green{
                // background-color: red;
                &:hover{
                    background-color: #00b3ac !important;
                    color: white !important;
                }
            }

        }
        
    }

    .appraisal-contact{
        a{
            color: #ff7328 !important;
            text-decoration : underline; 
        }
        
    }


    .appraisal-form{
        label{
            margin-top: 10px;
        }
        
        .vtp-form{
            h3{
                margin-top: 2rem;
                margin-bottom: 10px;
            }
        }

        .btn{

        }
    }

    

    .appraisal-payment{
        .btn{
            border-radius: 20px;
            padding : 5px 20px;
            font-weight: normal !important;
        }
    }
    @media only screen and (max-width: $maxWidthTablet){
        .warranty-steps{
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &>div{
                align-items: center;
                text-align: center;
                width: 100% !important;
                &.arrow-icon>svg{
                    transform: rotate(90deg);
                }
            }

            .extra-info{
                top: 50px;
                width: 100%;
                left: 0;
            }
            .aval{
                padding-bottom: 60px;
            }
        }

        .btn-open button{
            width: 100%;
            max-width: 400px;
            margin: auto;
        }

        .appraisal-payment{

            max-width: 90%
        }
        
    }

   
}