.amenities{
    &.notpdf{
        &.not-expand{
            max-height: 300px;
            overflow: hidden;
            position: relative;
            .span-expand{
                position: absolute;
            }
        }
        &.expand{
            max-height: 100%;
            text-align: right;
            .span-expand{
                position: relative;
            }
        }
        .span-expand{
            text-align: right;
            bottom: 0;
             right: 0;
             display: flex !important;
             justify-content: end !important;
        }
    }
    
}
