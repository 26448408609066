.container-progress-bar {
    height: 20px;
    padding-top: 5px;

    .progress-dot {
        width: 20px;
        height: 20px;
        top: 0px;
    }

    .progress {
        height: 10px;
    }
}

.percentage {
    margin-top: -18px;
}

.popover-level {
    .popover-arrow {
        display: none;
    }
    .header-title {
        font-size: 14px;
    }
    .header-subtitle {
        font-size: 12px;
    }
    .bi-exclamation-triangle-fill {
        font-size: 21px;
    }
    .footer-text {
        font-size: 10px;
    }
}