.financial-img-bbva {
    height: 55px;
}

.financial-img-santander {
    height: 30px;
}

.financial-img-itau {
    height: 50px;
}


.payment-methods-container {
    margin: 0px;
    padding: 12px;
    font-size: 14px;
    div,p,a,ul,li,td,tr,th{
        background-color: transparent !important;
    }
    p {
        margin: 0px;
        color: #8e929e;
    }

    ul {
        list-style: none;
        padding: 0px;
    }
    td,tr,table{
        border: none !important;
    }
}

.logo-black-friday{
    position: absolute;
    right: -12px;
    top: -34px;
}
.payment_file{
    text-decoration: underline;
}