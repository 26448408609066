$maxWidthTablet : 992px;
$maxWidthMiddle : 768px;
$maxWidthMobile: 576px;
.user-activities{
    .tw-divider{
        // display: none !important;
        margin-bottom: 0;
        margin-top: 10px;
        height: 0;
        &::before , &::after{
            display: none !important;
        }
    }

    .mobile-title{
        display: none;
    }
    .responsive-table{

        @media only screen and (max-width: $maxWidthMobile)  {
            table{
                display: block !important;
            }
            thead{
                display: none;
            }
            tbody{
                display: block !important;
            }
            tr{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                border: 1px solid var(--bs-gray-400);
                margin-bottom: 1rem;
                border-radius: 10px;
                padding: 10px;
                
            }
            td{
                border-bottom: none !important;
                border-radius: 10px !important;
                width: 50%;
                display: flex;
                padding: 5px 10px;
                flex-wrap: wrap;
                &:last-child{
                    align-items: end;
                    justify-content: flex-end;
                }
                p{
                    white-space: break-spaces !important;
                }
            }
            
            .mobile-title{
                display: inline;
                font-weight: 600;
                margin-right: 5px;
            }
        }
    }
    

    @media only screen and (max-width: $maxWidthMobile)  {
        .search-bar{
            width: 100%;
            margin-top: 1rem;
            &>div{
                width: 100%;
                &>div{
                    width: 100%;
                    input{
                        width: 100% !important;
                        max-width: 100% !important;
                        height: 3rem;
                    }
                }
            }
        }

        &.user-visits, &.user-warranties-requested,&.user-requested-mortgages{
            td{
                width: 100%;
                
            }
        }
        &.user-warranties-requested,&.user-requested-mortgages{
            td:last-child{
                justify-content: start;
            }
        }
    }
}

