.gallery {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 0.4rem;

  .gallery-carousel {
    .player-wrapper {
      &>div {
        @media screen and (max-width: 991px) {
          height: 300px !important;
        }
      }
    }
  }
}
  
.gallery-carousel.carousel,
.gallery-carousel .carousel-inner,
.gallery-carousel .carousel-item {
  height: 100%;
}
  
.gallery-carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  &.fit-contain{
    object-fit: contain;
  }
}
  
.gallery-arrow {
  width: 28px;
  height: 28px;
  background-color: white;
}
  
.gallery-counter {
  position: absolute;
  bottom: 10px;
  left: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0 8px;
}

.gallery-counter span {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: -0.05em;
}
  
.gallery-counter i {
  font-size: 13px;
}

.modal-gallery {
  .modal-body {
    overflow: hidden;
    padding-bottom: 0;

    .gallery-carousel {
      height: calc(100% - 60px);
      .carousel-inner {
        overflow: visible;
      }
      img {
        object-fit: contain;
      }
    }

    .counter-container {
      height: 60px;
      .gallery-counter {
        position: static;
      }
    }
  }
}  