.country-flag {
  width: 30px;
  height: 20px;
  border: 1px solid white;
  background-color: white;
  overflow: hidden;
}

.country-flag img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
}
