$maxWidthTablet : 992px;
$maxWidthMiddle : 768px;
$maxWidthMobile: 576px;

.custom-pagination{
    li{
        cursor: pointer;
        background-color: var(--bs-gray-200);
        color : black;
        
        &.active {
            background-color: var(--bs-primary);
            color: white;
        }
        &.pagination-move{
            padding-left: 14px !important;
            padding-right: 14px !important;
            &.disabled{
                opacity: 0.3;
                pointer-events: none;
            }
        }

        @media only screen and (max-width: $maxWidthMobile)  {
            padding: 5px 8px !important;
            margin: 5px !important;
            &.pagination-move{
                padding-left: 8px !important   ;
                padding-right: 8px !important;
            }
        }
    }
}