.dot_marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff7328;
  border: 3px solid white;
  box-shadow: 0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.45) !important;
}
.icon-marker {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 5px;
    left: 20px - 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    filter: blur(2px);
  }
  i {
    position: relative;
    z-index: 2;
  }
}
.marker-group {
  position: relative;
  height: 35px !important;
  & svg {
    margin-top: -25px;
    margin-left: 3.5px;
  }
}

.property-modal-map {
  .modal-body {
    @media screen and (max-width: 992px) {
      padding: 0;
      & > div {
        border-radius: 0 !important;
      }
    }
  }
}
