.ranking-items {
    font-size: 14px;

    @media screen and (max-width: 1291px) and (min-width: 992px) {
        font-size: 12px;
    }

    @media screen and (max-width: 373px) {
        font-size: 12px;
    }

    .level {
        font-size: 10px;
        font-weight: 700;
    }

    .username {
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        width: 150px;

        @media screen and (max-width: 1291px) and (min-width: 992px) {
            width: 80px;
        }

        @media screen and (max-width: 373px) {
            width: 80px;
        }
    }
}

.see-all {
    font-size: 14px;
}