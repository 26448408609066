.special-select{
  
    button.dropdown-toggle{
      width: 100%;
      text-align: left;
      background-color: white !important;
      border : 1px solid #cecece !important;
      position: relative;
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      padding-right: 0;
  
      &:focus{
        border-color : 1px solid #cecece !important;
        box-shadow: none !important;
      }
  
      &.invalid{
        border : 1px solid #dc3545 !important;
      }
  
      i{
        color : #8c8a8a;
        position: absolute;
        right: 10px;
        top: 10px;
  
        &.remove-all{
          right: 35px;
          top : 8px;
          &:hover{
            color : black;
          }
        }
      }
      
    }
  
    input{
      border: none;
      width: 100%;
      flex: 1;
      padding-left: 5px !important;
      padding-top:  0;
      padding-bottom: 0;
      &:focus{
        outline: 0;
        border: none !important ;
        box-shadow: none !important;
      }
      &.is-invalid{
        background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e") !important;
        background-repeat: no-repeat !important;
        background-position: right calc(2em + 0.1875rem) center !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
      }
    }
  
    .dropdown-menu{
      width: 100%;
      max-height: 250px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-top : 0px !important;
  
      ul{
        padding-left: 0px;
        margin-bottom: 0px !important;
        li{
          text-decoration: none;
          list-style: none;
  
        }
      }
  
      .dropdown-item{
        border-radius: 0px !important;
      }
      .dropdown-item:hover, .dropdown-item:focus{
        background-color: rgb(206 226 255 / 65%) !important;
      }
  
      .dropdown-item.active, .dropdown-item:active{
        background-color: #289aff !important;
        
      }
      
    }
  
    .multi-selection{
      background-color: hsl(0, 0%, 90%);
      border-radius: 3px;
      color : black;
      padding : 5px;
      margin : 0 3px;
      font-size: 85%;
      padding: 3px;
      padding-left: 6px;
      padding-right: 0;
  
      .multi-remove{
        padding: 3px 5px;
        border-radius: 0 3px 3px 0;
        margin-left: 1px;
        i{
          top : 2px;
          right: 0;
          position : relative;
          color : black;
        }
  
        &:hover{
          background-color: rgba(253, 186, 186, 0.415);
        }
      }
    }
  }