.sidebar {
  margin-top: 64px + 54px;
  @media screen and (max-width: 992px) {
    margin-top: 0px;
  }

  .filters {
    .accordion-header{
      &.hide-icon{
        .accordion-button::after{
          display: none;
        }
      }
    }
    
    .accordion-button {
      background: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
      box-shadow: none;
    }

    .amenitie-pill {
      &.active {
        border-color: var(--bs-primary) !important;
        font-weight: bold !important;
      }
    }

    .numbers-container {
      row-gap: 10px;

      .btn-mono {
        width: 60px !important;
      }

      .btn-number {
        color: var(--bs-dark) !important;
        border: solid 1.5px var(--bs-light);
        margin-right: 0.75rem;
        width: 40px;
        height: 40px;
        &:hover,
        &.active {
          border-color: var(--bs-primary) !important;
          font-weight: bold !important;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }

    .dot-filter {
      transition: all ease 0.35s;
      opacity: 1;
      width: 20px;
      font-size: 2.5rem;
      margin: -15px 17.5px -15px -20px;
      &.hide {
        opacity: 0;
        pointer-events: none;
        width: 0px;
      }
    }
    .input-price {
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
