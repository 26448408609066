.units-table {
  font-size: 14px !important;

  .units-table-header-col {
    padding: 0.7rem 0;
  }

  .units-table-row {
    cursor: pointer;
    
    &:hover {
      background-color: #F0F2F6 !important;
    }
  }

  @media screen and (max-width: 992px) {
    border: 0 !important;
    background-color: transparent !important;
  }

  @media screen and (min-width: 992px) {
    .units-table-row {
      border: 0 !important;
    }
  }

  .table-footer {
    .button-footer {
      &:not(.activo):hover {
        background-color: #ff7328 !important;
        border-color: #ff7328;
        color: #fff !important;
      }
    }
  }

  .promo-span{
    padding: 3px;
    border-radius: 5px;
    font-size: 9px;
    top: -18px;
    font-weight: bold;
    left: -17px;

    @media screen and (max-width : 992px) {
      font-size: 11px;
      top: -35px;
      left: -18px;
    }
  }
}

button.alert-new-close.btn.btn-primary {
  width: 20px !important;
  height: 20px !important;
  padding: 0px !important;
  font-size: 12px !important;
  border-radius: 50% !important;
  background-color: transparent !important;
  border: none !important;
  float: right !important;
}