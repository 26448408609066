.responsive-container {
    .property-table {
        font-size: 14px;

        .property-header {
            font-weight: 800;
            font-size: 12px;
            background-color: #F0F2F6;
            border-top: 1px solid #C1C5D2;
            border-bottom: 1px solid #C1C5D2;
            z-index: 3;

            @media screen and (min-width: 769px) {
                position: sticky;
                top: 100px;
                width: 101%;
            }
        }

        .table-row {
            border-bottom: 1px solid #C1C5D2;
            background-color: transparent;
            transition: background-color 0.5s ease;

            .invalid-link {
                pointer-events: none;
            }

            &:hover {
                background-color: #F0F2F6;
                transition: background-color 0.5s ease;
            }
        }

        .first-column {
            max-width: 100px;
        }

        .logo-promo {
            width: 90%;
            display: block;
            margin-top: 8px;
        }

        .tag-hand-over {
            background-color: #C1C5D2;
            color: #3B3E48;
            border-radius: 5px;
            padding: 2.5px 5px;
            font-size: 12px;
            font-weight: 800;
            text-transform: uppercase;
            text-align: center;
        }
        
        .tag-commision {
            background-color: #C3F5F3;
            color: #00B3AC;
            font-weight: 800;
            padding: 4px 8px;
            font-size: 12px;
            border-radius: 5px;
        }

        .text-dorms {
            min-width: 60px;
        }

        .btn-respuestas {
            padding: 5px;
            border-radius: 5px;
            background-color: transparent;
            transition: background-color 0.2s ease;

            &:hover {
                background-color: #ff7328;
                color: #fff;
                transition: background-color 0.2s ease;
                transition: color 0.2s ease;
            }

            &.disabled {
                color: #C1C5D2 !important;
                pointer-events: none;
            }

            &.invalid-level {
                color: rgba(255, 115, 40, 0.5);

                &:hover {
                    background: none !important;
                    cursor: not-allowed;
                }
            }
        }

        @media screen and (max-width: 768px) {
            width: 1040px;
            margin-top: 16px !important;
        }
    }

    @media screen and (max-width: 768px) {
        overflow-x: auto;
        margin-top: 5px !important;
    }
}