@import "../components/PropertyTable/PropertyTable.scss";
@import "../components/TogglePropertyList/TogglePropertyList.scss";
@import "../components/TogglePropertyOperation/TogglePropertyOperation.scss";
@import "../components/OverlayLevelBlock/OverlayLevelBlock.scss";
@import "../components/PromoModal/PromoModal.scss";

.list {
  padding-bottom: 1px;
  min-height: calc(100vh - 380px);
  box-sizing: border-box;
  .sidebarOpen {
    @media screen and (min-width: 768px) {
      padding-left: 400px;
    }
  }

  .pagination-custom {
    @media screen and (max-width: 992px) {
      font-size: 1rem !important;
      text-align: center;
    }
  }
  .progress-custom {
    background-color: #c1c5d2 !important;
    height: 4px;
    width: 98%;
    max-width: 350px;
  }

  .progress-bar {
    background-color: #3b3e48 !important;
  }

  .filter-header {
    position: sticky;
    left: 0;
    top: 54px;
    z-index: 1002;
    box-sizing: border-box;

    .filter-button {
      width: fit-content;
    }

    .sorter.dropdown-toggle::after {
      display: none;
    }

    .active-new {
      border: 4px solid rgba(255, 115, 40, 0.2);
      padding: 2px;
      border-radius: 10px;
    }
  }

  .empty-search {
    min-height: calc(100vh - 116px);
  }

  .invalid-link {
    pointer-events: none;
  }

  .loader-btn{
    top: 50%;
    right: 0px;
    transform: translate(0, -50%);
  }
}