.property-modal-backdrop.modal-backdrop {
    backdrop-filter: blur(15px);
    background-color: rgba(0, 0, 0, 0.7);
  
    &.show {
      opacity: 1;
    }
  }
  
  .property-modal {
    .modal-content {
      background: none;
    }
  
    .property-favorite {
      color: white !important;
    }
}