.virtual-sign__card{
    &__price{
        margin-bottom: -15px;
        margin-top: -10px;
    }
    &__old-price {
        top : -15px;
        left: 23%;
        
    }

    h2{
        margin-bottom: 0;
    }

    &:hover{
        border-width: 3px !important;
    }

    .custom-plan{
        width: auto !important;
        input {
            width: 100px;
            margin-right: 10px;
            margin-left: 10px;
        }
    }

    &.highlight{
        .top-card{
            background-color: var(--bs-primary);
            color: white;
        }
    }
    hr{
        margin-top: 0;
    }
}