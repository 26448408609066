.full-post {
  .post-card {
    min-height: 325px;
    transition: 200ms all ease;
  
    &:hover {
      box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.15) !important;
    }
    .post-card-cover {
      overflow: hidden;
      position: relative;

      .post-gallery {
        border-start-end-radius: 4px;
        border-start-start-radius: 4px;
      }
      .static-image {
        object-fit: cover;
        border-start-end-radius: 4px;
        border-start-start-radius: 4px;
      }
      .country-flag {
        position: absolute;
        top: 10px;
        left: 12px;
        z-index: 1;
      }
      iframe {
        border-radius: 0.4rem;
      }
    }
    .post-card-body {
      padding: 10px 12px;
      flex: 1;
      .post-card-location {
        font-size: 12px;
      }
      .post-card-title {
        font-size: 16px;
      }
    }
  }
}