@import "../components/ui/SpecialSelect.scss";

.admin-layout{

    h2{
        color: black !important;
    }

    .main-content{
        //background-color: #ff7328!important;
        color: #ff7328;
        margin: auto;
        margin-top: 4rem;
        border: 2px solid #cecece;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        max-width: 1000px;
        width: 70vw;
        border-radius: 15px;
        padding: 5rem 3rem;
        
        .admin-sections{
            max-width: 800px;
        }
    }

    .admin-section{
        cursor: pointer;
        border: 2px solid var(--bs-primary);
        border-radius: 20px;
        width: 33%;

        a{
            padding: 20px 5px;
            display: block;
            height: 100%;
            width: 100%;
            &:hover{
                color: white;
            }
            
        }


        &:hover{
            color: white;
            background-color: var(--bs-primary);
        }
    }

    .centered-section{

        border: 2px solid var(--bs-primary);
        border-radius: 20px;
        margin: auto;
        margin-top: 5rem;
        min-width: 200px;

    }
    .notification-info{
        p{
            margin-bottom: 0;
        }
    }

    .notification-form{
        max-width: 1100px;
        margin: auto;


        @media (min-width: 900px) {
            .col-left{
                border-right: 1px solid #cccccc8f;
            }
            
        }

        @media (min-width: 768px) {
            .columna{
                padding: 15px 2rem;
            }
        }

    }
    .alert-danger{
        position : relative !important;
        padding: 1rem 1rem;
        margin-bottom: 1rem;
    }

    .go-back {
        position: absolute;
        top: -10px;
        left: -40px;
        @media (max-width: 900px) {
            top : -60px;
            left: 0;
        }
        i{
            color: #3b3e48 !important;
        }
        &:hover i {
          color: #3b3e48 !important;
        }
    }

    .header-form{
        position: relative;
    }

    .specific-project{
        @media (max-width: 900px) {
            &>div{
                width: 100% !important;
                flex-direction: column !important;
                & > div{
                    width: 100%;
                    margin : 0 !important;
                }

            }
        }
    }
    
    
}