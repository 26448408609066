@import './../components/FeedBanner/FeedBanner.scss';
@import './../components/FeedBenefitsCard/FeedBenefitsCard.scss';
@import './../components/FeedBenefitsModal/FeedBenefitsModal.scss';
@import './../components/Search/Search.scss';
@import '../components/FeedPostCard/FeedPostDescription/FeedPostDescription.scss';
@import '../components/FeedPostCard/FeedPostCard.scss';
@import '../components/FeedPostCard/FeedPostHeader/FeedPostHeader.scss';
@import '../components/FeedPostCard/FeedPostGridGallery/FeedPostGridGallery.scss';
@import '../components/FeedPostCard/FeedPostTooltip/FeedPostTooltip.scss';
@import '../components/FeedPostCard/FeedPostFooter/FeedPostFooter.scss';
@import '../components/FeedRankingUsersCard/FeedRankingUsersCard.scss';
@import '../components/FeedInfoInmobiliaria/FeedInfoInmobiliaria.scss';
@import '../components/FeedPropertyCard/FeedPropertyCard.scss';
@import '../components/NotificationsDropdown/NotificationsDropdown.scss';
@import "./gallery.scss";
@import "./modal.scss";

.feed-container {
  min-height: calc(100vh - 54px);

  .feed-menu {
    .feed-menu-item:not(.active) {
      transition: 300ms all ease;

      &:hover {
        background-color: #f0f2f6 !important;
        color: #ff7328;
      }
    }
  }

  .feed-iris-coordinator {
    .coordinator-section {
      flex-direction: column !important;
      align-items: center !important;
      span {
        text-align: center !important;
        font-size: 0.9rem !important;
        font-weight: bold;
        margin-bottom: 0.7rem !important;
      }
    }
  }

  .feed-detail-gallery {
    height: 375px;
    min-width: 500px;
    @media screen and (max-width: 992px) {
      height: 300px;
      min-width: auto;
      max-width: 100%;
    }

    .gallery-counter {
      padding: 0.15rem 0.4rem;
      left: auto;
      right: 12px;
      i {
        font-size: 0.9rem;
      }
      span {
        font-size: 0.8rem;
      }
    }

    .container-pdf {
      position: relative;
      height: 100%;

      .link-pdf {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 10px 25px;
      }
    }
  }

  .go-back {
    cursor: pointer;
    width: 150px;
    
    &:hover {
      background-color: #f0f2f6;
    }
  }
}
