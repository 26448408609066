.filter-country {
  &:focus {
    box-shadow: none !important;
  }
  & + .dropdown-menu.show {
    min-width: fit-content;
  }
}

.dropdown-toggle::after {
  display: none;
}
