.property-form-modal.modal-backdrop {
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.7);
  &.show {
    opacity: 1;
    z-index: 1200;
  }
}

.property-form-modal-content{
  z-index: 1300;
}
