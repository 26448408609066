.post-grid-gallery {
    img {
        cursor: pointer;
    }

    .fit-cover {
        object-fit: cover;
    }

    .fit-contain {
        object-fit: contain;
        background-color: #e9ecef;
    }

    .overlay-post-image {
        width: 100%;
        height: 146px;
        opacity: 0.3;
        border-radius: 7px;
    }

    .overlay-number {
        cursor: pointer;

        h1 {
            cursor: pointer;
        }
    }
}