.search-header {
  width: 75% !important;

  @media screen and (max-width: 1199px) {
    width: 100% !important;
  }

  @media screen and (max-width: 991px) {
    margin: 8px 0px !important;
  }

  .input-group-text {
    border: none !important;
  }

  .form-control {
    border: none !important;
    padding-right: 0px;
  }
}

.search-filters {
  .input-group-text {
    border: none !important;
    background: #FF7328 !important;
    color: #fff !important;
  }

  .form-control {
    background: #fff !important;
  }
}

