.sale-form{

    max-width: 1100px;
    margin: auto;
    
    
    @media (min-width: 900px) {
        // .{
        //     border-right: 1px solid #cccccc8f;
        // }
        
    }

    @media (min-width: 768px) {
        .header-form{
            padding: 15px 2rem;
        }
    }
    
}