.neighbourhood-description {
    position: relative;
    &:not(.expand) {
      p {
        overflow: hidden;
        text-overflow: clip;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
      }
  
      .more {
        width: 53px;
        line-height: 1.2rem;
      }
    }
  
    .more {
      text-align: right;
      position: absolute;
      bottom: -1px;
      right: 0;
      display: inline-block;
      padding-left: 0px;
    }
  
    &.expand {
      p {
        margin-bottom: 22px;
      }
      .more {
        bottom: -22px;
      }
    }
  }
  