.admin-sales-list{
    table{
        // table-layout: fixed;
    }
    th,td{
        text-align: center !important; 
    }
        
    th{
        // display: flex !important;
        // align-items: center;
        // justify-content: center;
    }
    

    th:nth-child(7),td:nth-child(7){
        width: 140px !important;
    }

    .dropdown-toggle:after{
        display: inline-block;
    }
    .sales-filter-search{
        width: 350px;
        @media(max-width: 600px){
            margin-right: 0 !important;
            width: 100% !important;
            input{
                margin-right: 0;
            }
        }
    }
    .sales-filter-status{
        @media(max-width: 600px){
            span{
                margin: 0 !important;
                margin-right: 10px !important;
            }
            .dropdown{
                flex: 1;
                width: 100%;
                button{
                    width: 100%;
                }
            }
            width: 100% !important;
        }
    }

    
    @media(max-width: 600px){
        .export-excel{
            margin-top: 1rem;
            width: 100%;
            button {
                width: 100% ;
            }
        }
    }   
}