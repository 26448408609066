.filter-estate {
  &:focus {
    box-shadow: none !important;
  }
}

.dropdown-toggle::after {
  display: none;
}

.neighbourhood-check {
  width: 100%;
  .form-check{
    width: 100%;
    padding: 0;
    label{
      margin-left: 5px;
      width: calc(100% - 20px);
    }
  }
}