.user-level{
    .progress{
        height: 8px;
    }
    .container-progress-bar .progress-dot{
        width: 16px;
        height: 16px;
        top : 1px;
    }
    .next-steps{
        & > div{
            label{
                font-size: 13px;
            }
            span{
                font-size: 13px;
            }
        }
        .header-subtitle{
            font-size: 14px;
        }
        .progress{
            height: 6px;
        }
    
        &.hidden-half{
            height: 180px;
            overflow: hidden;
            -webkit-mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
        }
        
        
    }

    .btn-more{
        top: 300px;
        z-index: 2;
        left: 50%;
        transform: translate(-50%,0%);
    }
}
