.tabs-container {
  background-color: #f0f2f6;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 100%;
    width: 100vw;
    height: 100%;
    background-color: #f0f2f6;
    border-bottom: 1px solid #dee2e6;
    box-sizing: content-box;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100vw;
    width: 100vw;
    height: 100%;
    background-color: #f0f2f6;
    border-bottom: 1px solid #dee2e6;
    box-sizing: content-box;
  }

  @media screen and (min-width: 992px) {
    background-color: transparent;
    &::before,
    &::after {
      display: none;
    }
  }
  .tabs {
    overflow: auto;
    overflow-y: hidden;
    .tab {
      border-bottom: 2px solid transparent;
      position: relative;
      bottom: -1px;
      text-align: center;
      &.tab-active {
        border-color: #ff7328;

        @media screen and (max-width: 992px) {
          border-bottom: 3px solid #ff7328;
        }
      }
      span {
        font-size: 0.775rem !important;
      }

      @media screen and (min-width: 992px) {
        width: 120px;
        span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
}
