@import "./../components/PropertyCard/PropertyCard.scss";

.home-banner-top {
  .home-banner-top-text-container {
    position: absolute;
    top: 0%;
    left: 0%;
    .home-banner-top-text {
      width: 75%;
      color: white;
      &.right {
        left: 50%;
      }
      .home-banner-btn {
        width: 100%;
        max-width: 220px;
        padding-top: 0.65rem;
        padding-bottom: 0.65rem;
        font-weight: 500;
      }
    }
    .home-banner-message {
      p {
        font-size: 18px;
        margin-top: 13px;
        width: 80%;
        text-align: center;
        @media screen and (max-width: 768px) {
          font-size: 12px;
          margin-top: 9px;
        }
      }
      img {
        width: 300px;
        height: auto;

        @media screen and (max-width: 768px) {
          width: 200px;
          height: auto;
        }
      }
    }
  }

  .gradient-overlay {
    background: linear-gradient(90deg, rgba(0,0,0,0.5702613809195554) 51%, rgba(255,255,255,0) 100%); 
    height: 500px;
  }

  .home-banner-top-cta {
    z-index: 1;
    width: 70%;
    max-width: 1000px;
    top: 50%;
    margin-left: 50px;

    @media screen and (min-width: 573px) {
      left: 35%;
      transform: translate(-50%,-50%);
    }

    @media screen and (max-width: 768px) {
      margin-left: 20px;

      .home-banner-title {
        font-size: 24px;
      }
      .home-banner-top-texto {
        font-size: 14px !important;
        strong{
          font-size: 14px !important;
        }
      }
      .home-banner-btn {
        font-size: 16px !important;
      }
    }

    @media screen and (max-width: 574px) {
      top: 20%;
    }

    @media screen and (max-width: 420px) {
      top: 20%;
      margin-top: -15%;
    }
  }

  img {
    height: 500px;
    object-fit: cover;
    object-position: center;
  }

  //sm breakpoint
  @media screen and (max-width: 768px) {
    height: 400px;

    .carousel-indicators {
      margin-bottom: 0.5rem;
    }
    .carousel-inner {
      height: 100%;
    }
    img {
      object-position: right;
      &.right {
        object-position: left;
      }
    }
    .home-banner-top-text-container {
      .home-banner-top-text {
        width: auto;
        max-width: 75%;
        .fs-1 {
          font-size: calc(0.675rem + 1.5vw) !important;
        }
        .home-banner-title {
          margin-bottom: 0.2rem !important;
        }
        .home-banner-top-texto {
          font-size: calc(0.8rem + 0.6vw) !important;
          margin-top: 0.4rem !important;
          margin-bottom: 0.4rem !important;
          max-width: 235px;
          strong{
            font-size: calc(0.8rem + 0.6vw) !important;
          }
        }
        .home-banner-btn {
          max-width: 140px;
          padding-top: 0.35rem;
          padding-bottom: 0.35rem;
          font-size: 0.85rem;
        }
      }
    }
  }

  // custom resize breakpoint
  @media screen and (max-width: 420px) {
    img {
      object-position: 70%;
      &.right {
        object-position: 30%;
      }
    }
  }
}

.home-how-it-work {
  h5 {
    flex: 1;
  }
  img {
    object-fit: contain;
    object-position: center;
  }
}

.home-description-section {
  background-image: url("https://cdn1.infocasas.com.uy/web/620bea5021c74_infocdn__buildings.png");
  background-repeat: no-repeat;
  background-position: 90% 110%;
  background-size: 42%;

  // custom resize breakpoint
  @media screen and (max-width: 573px) {
    min-height: 450px !important;
    background-position: 0% 92%;
    background-size: 100%;
  }
}

.home-join-platform {
  .card {
    .card-header {
      background-color: #00b3ac;
    }
  }
}

.card-categories {
  height: 350px;
  border-radius: 12px;
  padding: 20px;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #C1C5D2;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 285px;
  }

  .image-category {
    width: 200px;

    @media (max-width: 768px) {
      width: 185px;
    }
  }

  .title-categories {
    color: #FF7328;
    font-weight: 600;
    text-align: center;
    font-size: 28px;
    margin-top: 20px;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .button-categories {
    width: 150px;
    border: 1px solid #fff;
    background: rgba(1, 1, 1, 0.4);
    align-self: end
  }
}