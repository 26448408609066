.line:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 62%;
    border-top: 1px solid #383838;
    background: #383838;
    width: 38%;
    transform: translateY(-50%);
}

.title, .reference {
    margin-left: -20px;
}

.page-header-space {
    height: 110px;
}

.page-header {
    display: flex;
    align-items: flex-start;
    position: fixed;
    top: 0mm;
    width: 100%;
    border-bottom: 1px solid #C1C5D2;
    padding-right: 20px;
}

.property-download{
    white-space: nowrap;
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    thead {display: table-header-group;} 
    tfoot {display: table-footer-group;}
}

@media print {
    .page {
        page-break-after: always;
    }
}
  
@page {
    size: auto;
    margin: 20mm;
}