.warranty-modal {
    .container {
        font-size: 14px;

        .row {
            @media (max-width: 768px) {
                flex-flow: column;
            }
        }
        .modal-warranty-contact{
            flex-wrap: wrap;
            button{
                border: 1px solid #00b3ac;
                &:hover{
                    background-color: #00b3ac !important;
                    color: white !important;
                }
            }
        }
    }

    ul{
        list-style: disc;
    }
}