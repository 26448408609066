$maxWidthTablet : 992px;
$maxWidthMiddle : 768px;
$maxWidthMobile: 576px;
.user-sales{
    .tw-divider{
        // display: none !important;
        margin-bottom: 0;
        margin-top: 10px;
        height: 0;
        &::before , &::after{
            display: none !important;
        }
    }
    .tw-divider.divider-show{
        margin-bottom: 10px !important;
        margin-top: 20px;
        height: 0;
        &::before , &::after{
            display: block !important;
        }
    }

    .mobile-title{
        display: none;
    }
    .responsive-table{

        table-layout:fixed;
        th{
            text-align: center;
        }
        td,th{
            white-space: break-spaces;
            font-size: 14px;
            text-align: center;
            // padding-top: 1rem;
            // padding-bottom: 1rem;
        }
        td.sales-status{
            font-size: 16px;
        }
        
        .bg-info{
            border: 1px solid var(--bs-primary);
            background-color: white !important;
            color: var(--bs-primary);
        }

        @media only screen and (max-width: $maxWidthTablet)  {
            table{
                display: block !important;
            }
            thead{
                display: none;
            }
            tbody{
                display: block !important;
            }
            td,th{
                white-space: break-spaces;
                font-size: 16px;
            }
            tr{
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                border: 1px solid var(--bs-gray-400);
                margin-bottom: 1.5rem;
                border-radius: 10px;
                padding: 10px;
                
            }
            td{
                border-bottom: none !important;
                border-radius: 10px !important;
                width: 50%;
                display: flex;
                padding: 5px 10px;
                flex-wrap: wrap;
                &:last-child{
                    align-items: end;
                    justify-content: flex-end;
                }
                p{
                    white-space: break-spaces !important;
                }
            }
            
            .mobile-title{
                display: inline;
                font-weight: 600;
                margin-right: 5px;
            }
            td.sales-bill,td.sales-voucher{
                align-items: center;
                justify-content: space-between;
                svg{
                    width: 30px;
                }
            }
        }
    }
    
    .unit-name{
        // color: #cecece;
        font-weight: 100;
        font-size: 14px;
        @media only screen and (max-width: $maxWidthTablet)  {
            font-size: 16px;
        }
    }
    .unit-price{
        color: black !important;
        font-weight: 600;
    }

    .tw-shadow{
        box-shadow: 1px 1px 10px 2px rgba(255, 77, 0, 0.3) !important;
    }
    .tw-stat{
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .tw-stat-value {
        font-size: 22px;
    }
    
    th:first-child{

    }
    th:last-child{
        // max-width: 80px !important;
    }

    @media only screen and (max-width: $maxWidthTablet)  {
        .search-bar{
            & > div{
                display: block;
            }
        }
    }
    @media only screen and (max-width: $maxWidthMiddle)  {
        .search-bar{
            width: 100%;
            margin-top: 0;
            &>div{
                width: 100%;
                &>div{
                    width: 100%;
                    input{
                        width: 100% !important;
                        max-width: 100% !important;
                        height: 3rem;
                    }
                }
            }
        }

        &.user-visits, &.user-warranties-requested,&.user-requested-mortgages,&.user-sales{
            td{
                width: 100%;
                p{
                    margin-right: 5px;
                }
            }
        }
        &.user-warranties-requested,&.user-requested-mortgages{
            td:last-child{
                justify-content: start;
            }
        }
        
    }
}

.btn-upload-bill-file{
    min-width: 150px;
    padding: 5px 40px;
    width: auto;
    @media only screen and (max-width: $maxWidthMobile)  {
        width: 100%;
    }

}

