.post-description {
  position: relative;
  &:not(.expand) {
    p {
      overflow: hidden;
      text-overflow: clip;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      margin-bottom: 0;
    }
  }

  &.expand {
    p {
      margin-bottom: 22px;
    }
  }

  a {
    cursor: pointer;
  }
}
