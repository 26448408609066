.custom-card {
    border-radius: 10px !important;

    &.home {
        background-color: #F8F9FD !important;
        border: 1px solid #C1C5D2 !important;

        @media (min-width: 574px) {
            flex-flow: row;
        }

        .left-side {
            @media (max-width: 573px) {
                height: 135px;
            }

            @media (min-width: 574px) {
                width: 35%;

                .mortgage-badge {
                    bottom: 0px;                
                }
            }

            .card-img-top {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .card-body {
            padding: 20px;

            @media (min-width: 574px) {
                width: 65%;
            }

            .card-title {
                font-weight: 700;
            }

            button {
                color: #fff !important;
                // background-color: #FF7328 !important;
                border: none !important;
            }
        }
    }

    .card-title {
        font-size: 14px;
    }

    .card-text, button {
        font-size: 13px;
    }
    .warranty-status{
        object-position: 78%;
        @media (max-width: 574px) {
            object-position: center 10%;
        }
    }
}