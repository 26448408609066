.filter-estate {
  &:focus {
    box-shadow: none !important;
  }
  
}

.dropdown-toggle::after {
  display: none;
}

.dropdown-menu.show {
  min-width: 210px !important;
  .form-check-input {
    margin: 0px;
  }
  
  .badge-check {
    padding: 2px 7px 2px 7px;
  }
}

.neighbourhood-collapse {
  // max-width: 200px;
  font-size: 14px;
  padding: 0px 20px;
  padding-left: 25px;

  .neighbourhood-main-check{
    padding-left: 0px;
    label{
      margin-left: 5px;
      max-width: calc(100% - 20px);
    }
  }
  .neighbourhood-check {
    // color: #737373;
  }
  
  .dropdown-item:hover , .dropdown-item:focus{
    background-color: white !important;
  }

  
  
}

.commune-collapse{
  color: #737373;
  padding: 0px 10px;
  .commune-check{
    label{
      max-width: calc(100% - 1em - 5px);
      margin-left: 5px;
    }
  }
}

.custom-accordion {
  .accordion-button {
    padding: 10px;
    border-radius: 5px !important;

    &:not(.collapsed) {
      color: #3B3E48;
      background-color: #F0F2F6;
      box-shadow: none;
    }

    &::after {
      background-size: 16px !important;
      width: 16px !important;
      height: 16px !important;
    }

    .form-check-input {
      margin: 0px;
    }
  }

  .badge-check {
    padding: 2px 7px 2px 7px;
  }
}

.form-check-estate{
  padding-left: 10px !important;
  label{
    margin-left: 5px;
  }
}