.notifications-dropdown {
    align-self: center;
    margin-top: 2px;

    .notifications-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 700;
        font-size: 13px;
        padding: 0px 5px 10px 5px;
        border-bottom: 1px solid #C1C5D2;

        button {
            border: 1px solid #C1C5D2;
            border-radius: 8px;
            background: none;
            font-weight: 700;
            font-size: 12px;
            padding: 6px;
        }
    }

    .notifications-button {
        border: none !important;
        border-radius: 50% !important;
        position: relative;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px px;

        i {
            font-size: 18px;
        }

        .notification-dot {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 11px;
        }
    }

    .dropdown-menu {
      box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
      padding: 10px;
      width: 320px;
      height: 600px;
      overflow: auto;
    }
}

.mobile-notifications {
    padding: 8px 0px;
    display: flex;
    align-items: center;
    border: none;
    background: none;
    color: #3B3E48;

    .bi-circle-fill {
        font-size: 7px;
        margin-left: 10px;
    }
}

.offcanvas-notifications {
    .container-offcanvas-logo {
        width: 100%;
        border-bottom: 1px solid #C1C5D2;
        padding: 12px 20px;

        .offcanvas-logo {
            height: 38px;
        }
    }

    .offcanvas-header {
        border-bottom: 1px solid #C1C5D2;
        flex-flow: column;
        padding: 0px;

        .offcanvas-title {
            width: 100%;
            padding: 10px 20px;
            font-size: 16px;
            display: flex;
            align-items: center;

            .bi-arrow-left {
                font-size: 25px;
            }
            img{
                max-width: 100px;
            }
        }

        .close-button {
            border: none;
            background: none;
        }
        
        span {
            font-weight: 600;
        }
    }

    .button-mark {
        padding: 10px;
        margin: 10px 0px;
        font-weight: 600;
        border: 1px solid #C1C5D2 !important;
        border-radius: 8px;
        background: none;
        width: 100%;
    }
}

.dropdown-item {
    display: flex;
    padding: 10px 15px;
    
    align-items: center;

    &.border-bottom {
        border-bottom: 1px solid #C1C5D2;
    }

    &:hover {
        transition: 100ms ease-in all;
        border-radius: 8px;
    }

    
    img {
        width: 60px;
        object-fit: cover;
        border-radius: 8px;
        height: 60px;
        
    }
    &.warranty-notification{
        img{
            object-fit: contain;
        }
    }

    .bi-dot {
        font-size: 35px;
        width: 20px;
        color: #E6E6E6;
    }

    .description {
        width: calc(100% - 80px);
        padding-left: 15px;

        p {
            font-size: 14px;
            white-space: normal;
            margin-bottom: 0px;
        }
        
        .datetime {
            font-size: 12px;
            color: #979797;
        }
        strong{
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}

.empty-notifications {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    color: #cdcdcd;

    .bi-inbox {
        font-size: 25px;
    }
}