.popover-custom {
    margin-top: -40px;
    .publish-by-description {
        overflow: hidden;
        text-overflow: clip;
        display: -webkit-box;
        -webkit-line-clamp: 4; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 0;
    }

    .popover-arrow {
        display: none;
    }
    
    .fit-cover {
        object-fit: cover;
    }

    .overlay-post-image {
        width: 100%;
        height: 100%;
        opacity: 0.3;
        border-radius: 7px;
    }

    .overlay-number {
        cursor: pointer;

        h1 {
            cursor: pointer;
        }
    }
}