@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

:root{
    --inmo-primary-color : #ff7328;
    --inmo-primary-color-rgb : 255, 115, 40;
}

.inmo-detail{
    .text-primary{
        color: var(--inmo-primary-color) !important;
    }
    .tabs-container .tabs .tab.tab-active{
        border-color: var(--inmo-primary-color) !important;
    }

    .btn-outline-primary{
        color : var(--inmo-primary-color) !important;
        border-color: var(--inmo-primary-color) !important;
        &:hover{
            background-color: var(--inmo-primary-color) !important;
            color : white !important;
        }
    }
    .btn-outline-primary.text-secondary{
        color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important ;
        &:hover{
            color : white !important;
        }
    }

    .btn-primary{
        border-color: var(--inmo-primary-color) !important;
    }

    .border-primary{
        border-color: var(--inmo-primary-color) !important;
    }

    .detail-gallery .download-images{
        color : var(--inmo-primary-color) !important;
    }

    .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus{
        box-shadow: 0 0 0 0.25rem  rgba( var(--inmo-primary-color-rgb), 0.5) !important;
    }

    .btn:focus{
        box-shadow: 0 0 0 0.25rem  rgba( var(--inmo-primary-color-rgb), 0.25) !important;
    }

    .unit-select-btn:not(.activo):hover{
        background-color: var(--inmo-primary-color) !important;
        border-color: var(--inmo-primary-color) !important;
    }

    .units-table .table-footer .button-footer:not(.activo):hover{
        background-color: var(--inmo-primary-color) !important;
    }

    .wpp-phone{
        color : #075E54;
        text-decoration: none;
        border: 1px solid #075E54;
        border-radius: 15px;
        padding: 5px 10px;
        &:hover{
            color : white;
            background-color: #075E54;

        }
        i {
            margin-right: 5px;
        }
    }
}

.inmo-detail-modal{
    .text-primary{
        color : var(--inmo-primary-color) !important;
    }

    .btn-primary{
        background-color: var(--inmo-primary-color) !important;
        border-color: var(--inmo-primary-color) !important;
    }
}