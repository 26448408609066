.overlay-invalid-level {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    backdrop-filter: blur(5px);
    padding-inline: 20px;
    z-index: 2;

    &.type-grid {
        flex-flow: column;
        justify-content: center;
        border-radius: 10px;
        
        &>p{
            text-align: center;
        }

        .lock-container {
            width: 50px;
            height: 50px;

            i {
                font-size: 30px;
            }
        }
    }

    .lock-container {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #3B3E48;
        color: #fff;
        margin-right: 15px;

        i {
            font-size: 22px;
        }
    }
}