@import "../../styles/custom.scss";

.form-toggle-button {
  box-shadow: none !important;
  &:not(.active):hover {
    background-color: #f0f2f6 !important;
    color: $primary !important;
  }
}

.schedule-visit-button {
  box-shadow: none !important;
  &:not(.activo):hover {
    background-color: $primary !important;
    border-color: $primary;
    color: #fff !important;
  }

  &:is(.activo):hover {
    background-color: #fff !important;
    border-color: $primary;
    color: #212529 !important;
  }
}

.date-calendar {
  .btn {
    box-shadow: none !important;

    &:is(.selected):hover {
      background-color: #fff !important;
      border-color: #ff7328;
      color: #212529 !important;
    }
  }
}

.send-button {
  height: 42px;
}

.form-send-offer {
  .form-select {
    font-size: 0.875rem;
    padding: 0.5rem 2.25rem 0.5rem 0.75rem;
  }
  .form-control {
    font-size: 0.875rem;
    padding: 0.5rem 2.25rem 0.5rem 0.75rem;
  }

  .payment-dropdown {
    padding: 9px 12px;
    color: #212529 !important;

    i, span {
      color: #212529;
    }
  }

  .btn-success:disabled, .btn-success.disabled {
    background-color: #00B3AC !important;
    border-color: #00B3AC !important;
  }

  #formSendOfferUnits.form-select:valid, .form-select.is-valid, .payment-dropdown.border-success, #formSendOfferMessage.form-control:valid {
    border-color: #00B3AC !important;
    box-shadow: none !important;
  }
}

.form-schedule-visit {
  .activo, .selected {
    font-weight: 700;
    border: 2px solid #ff7328;
  }

  .btn-success:disabled, .btn-success.disabled {
    background-color: #00B3AC !important;
    border-color: #00B3AC !important;
  }

  #formSendOfferMessage.form-control:valid, .form-control.is-valid {
    border-color: #00B3AC !important;
  }
}

.was-validated .form-control:valid, .form-control.is-valid {
  background-image: url('https://cdn1.infocasas.com.uy/web/62448482c010d_frame-1-(4).png');
  background-size: 25px;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  background-image: url('https://cdn1.infocasas.com.uy/web/62448482c010d_frame-1-(4).png');
  background-size: 25px;
}

.alert-danger {
  position: absolute;
  bottom: 0px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 0px;
  width: 100%;

  p {
    margin: 0px;
    font-size: 12px !important;
  }
  
  .alert-btn {
    background: none;
    border: none;
    padding: 0px;
    position: absolute;
    top: 0;
    right: 4px;

    i {
      color: #842029;
    }
  }
}