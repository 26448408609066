@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

:root{
    --uy-primary-color : #02A8E2;
    --uy-primary-color-rgb : 2, 168, 226;
}

.country-style-uy{

    .text-primary{
        color: var(--uy-primary-color) !important;
    }
    .tabs-container .tabs .tab.tab-active{
        border-color: var(--uy-primary-color) !important;
    }

    .btn-outline-primary{
        color : var(--uy-primary-color) !important;
        border-color: var(--uy-primary-color) !important;
        &:hover{
            background-color: var(--uy-primary-color) !important;
            color : white !important;
        }
    }

    .btn-outline-primary.text-secondary{
        color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important ;
        &:hover{
            color : white !important;
        }
    }
   

    .btn-primary, .tw-btn-primary{
        border-color: var(--uy-primary-color) !important;
        background-color : var(--uy-primary-color) !important;
        &:hover{
            background-color: rgba( var(--uy-primary-color-rgb), 0.7) !important;
            border-color:rgba( var(--uy-primary-color-rgb), 0.7) !important; ;
        }
    }

    .border-primary{
        border-color: var(--uy-primary-color) !important;
    }

    .detail-gallery .download-images{
        color : var(--uy-primary-color) !important;
    }

    .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus{
        box-shadow: 0 0 0 0.25rem  rgba( var(--uy-primary-color-rgb), 0.5) !important;
    }

    .btn:focus{
        box-shadow: 0 0 0 0.25rem  rgba( var(--uy-primary-color-rgb), 0.25) !important;
    }
    
    .btn-toogle-list{
        background-color: transparent !important;
        border: none !important;
        &.active{
            svg, i, span{
                color: var(--uy-primary-color);
            }
        }
    }

    .bg-primary-light{
        background-color: rgba(var(--uy-primary-color-rgb), 0.2) !important;
    }

    .bg-primary{
        --bs-bg-opacity: 1;
        background-color: rgba(var(--uy-primary-color-rgb), var(--bs-bg-opacity)) !important;
    }

    .form-toggle-button:not(.active):hover{
        color: var(--uy-primary-color) !important;
    }

    .form-control:focus{
        box-shadow: 0 0 0 0.25rem rgba(var(--uy-primary-color-rgb), 0.25);
    }

    //NAV:
    .nav-link{
        &.active{
            color: var(--uy-primary-color) !important;
        }
        
    }
    
    .header{
        .links-hoverable{
            &:hover{
                color :  var(--uy-primary-color) !important;
            }
        }
        .header-dropdown .dropdown-toggle:hover{
            color :  var(--uy-primary-color) !important;
        }
    }
    

    .accordion-button:not(.collapsed){
        color :  var(--uy-primary-color) !important;
    }

    //Form
    .form-check-input{
        &:checked{
            background-color: var(--uy-primary-color) !important;
            border-color: var(--uy-primary-color) !important;
        }
        &:focus {
            box-shadow: 0 0 0 0.25rem rgba(var(--uy-primary-color-rgb), 0.25);
        }
    }


    //Link

    a{
        color: black !important;
        &:hover{
            // color :  var(--uy-primary-color) !important;
        }
        

    }

    .profile-sidebar{
        a{
            &:focus {
                background-color: rgba(51, 51, 51, 0.1) !important;
            }
        }
    }
    .unit-select-btn:not(.activo):hover{
        background-color: var(--uy-primary-color) !important;
        border-color: var(--uy-primary-color) !important;
    }

    .units-table .table-footer .button-footer:not(.activo):hover{
        background-color: var(--uy-primary-color) !important;
    }

    .schedule-visit-button {
        box-shadow: none !important;
        &:not(.activo):hover {
          background-color: var(--uy-primary-color) !important;
          border-color: var(--uy-primary-color);
          color: #fff !important;
        }
      
        &:is(.activo):hover {
          background-color: #fff !important;
          border-color: var(--uy-primary-color);
          color: #212529 !important;
        }
    }

    .wpp-phone{
        color : #075E54;
        text-decoration: none;
        border: 1px solid #075E54;
        border-radius: 15px;
        padding: 5px 10px;
        &:hover{
            color : white;
            background-color: #075E54;

        }
        i {
            margin-right: 5px;
        }
    }

    .sidebar .filters {
        .numbers-container .btn-number{
            &:hover{
                border-color: var(--uy-primary-color) !important;
            }
            &.active{
                border-color: var(--uy-primary-color) !important;
            }
        }
        .amenitie-pill{
            &.active{
                border-color: var(--uy-primary-color) !important;
            }
        }
    }
    

    .tw-bg-primary{
        background-color: var(--uy-primary-color) !important;
    }

    .custom-pagination li.active{
        background-color:  var(--uy-primary-color) !important;
    }
}

.inmo-detail-modal{
    .text-primary{
        color : var(--uy-primary-color) !important;
    }

    .btn-primary{
        background-color: var(--uy-primary-color) !important;
        border-color: var(--uy-primary-color) !important;
    }
}