.property-agent-commision {
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
  padding: 2px 4px 1px;
  position: relative;
  
  .promo-commission{
    display: inline-block;
    text-align: center;
    position: relative;
    
  }
  .promo-commission-now{
    
    span{
      line-height: 13px;
    }
    
  }
  .text-before{
    top: -18px;
    font-size: 12px;
    
  }
}

.text-before{
  display: none;
}
.detail-price{
  .promo-commission{
    margin: 0 5px;
  }
  .promo-commission-now{
    color: var(--bs-primary) !important;
    margin: 0 5px;
  }
  .text-before{
    display: inline;
  }
  .new-commission{
    font-size: 20px;
    line-height: 20px;
    color: var(--bs-primary) !important;
  }
}

.property-card{
  &:hover{
    .text-hoverable{
      width: auto;
      opacity: 1;
      transition: 200ms ease-in all;
    }
  }
  .text-hoverable{
    width: 0px;
    display: inline-flex;
    opacity: 0;
    white-space: nowrap;
    margin-right: 4px;
  }
}

.strikethrough {
  position: relative;
  text-decoration: line-through;
}
.strikethrough:before {
  display: none;
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  
  -webkit-transform:rotate(-5deg);
  -moz-transform:rotate(-5deg);
  -ms-transform:rotate(-5deg);
  -o-transform:rotate(-5deg);
  transform:rotate(-5deg);
}