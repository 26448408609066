.header {
  height: 54px;
  box-sizing: border-box;

  .toggle-button {
    position: relative;
    font-size: 30px;

    .notification-dot {
      font-size: 8px !important;
      position: absolute;
      top: 0;
      right: 8px;
    }
  }

  .header-logo {
    height: 38px;
  }

  .header-logo-pdf {
    height: 10px;
  }

  .links-hoverable {
    transition: all 20ms ease;
    &:hover {
      color: #ff7328 !important;
    }
  }

  .header-dropdown {
    align-self: center;

    &.notifications-dropdown {
      .notifications-button {
        border: none !important;
        border-radius: 50% !important;
  
        i {
          font-size: 18px;
        }
      }
    }

    .dropdown-toggle {
      color: #3b3e48 !important;

      &:hover {
        color: #ff7328 !important;
      }
    }

    .dropdown-menu {
      box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.15);
    }
  }

  .nav-link.chile{
    padding: 0.5rem !important;
  }

  .user-name {
    max-width: 250px;
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-align: right;
    }
  }
}

#offcanvasNavbar {
  .offcanvas-logo {
    height: 38px;
  }
  .btn-close{
    background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat !important;
  }
}

.header-ghost {
  height: 54px;
}

.banner-promo {
  width: 100%;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
}

.services-accordion {
  .accordion-item {
    border: none;
  }

  .accordion-button {
    padding: 8px 0px;

    &:not(.collapsed) {
      color: #3B3E48 !important;
      background: none;
      box-shadow: none !important;
    }

    &:focus {
      box-shadow: none !important;
    }
  }

  .accordion-body {
    padding-top: 5px;
    
    .service-button {
      background: transparent !important;
      border-color: transparent !important;
      color: #3B3E48 !important;
    }
  }
}
.logo-mobile{
  max-width: 100px;
}