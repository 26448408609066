.unit-select-btn {
  box-shadow: none !important;
  &:not(.activo) {
    border-color: transparent;
    &:hover {
      background-color: #ff7328 !important;
      border-color: #ff7328;
      color: #fff !important;
    }
  }

  &:is(.activo):hover {
    background-color: transparent !important;
    border-color: #ff7328;
    color: #212529 !important;
  }
}
